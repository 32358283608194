import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "@components/header/Header.tsx";
import NavBarLogged from "@components/navBarLogged/NavbarLogged.tsx";
import "./myBatiment.scss";
import { sensor } from "@tsTypes/sensorType.ts";
import BackButton from "./components/backButton/BackButton";
import SelectSensor from "./components/selectSensor/SelectSensor";
import SelectMeasure from "./components/selectMeasure/SelectMeasure";
import useSWR from "swr";
import { GetFetcher } from "@api/SwrFetcher";
import DateRange from "./components/dateRange/DateRange";
import MyBatimentChart from "./components/myBatimentChart/MyBatimentChart";
import Loading from "@components/loading/Loading";
import { Button, useDisclosure } from "@chakra-ui/react";
import SubCounterDrawer from "./components/subCounterDrawer/SubCounterDrawer";
import SettingsButton from "./components/settingsButton/SettingsButton";
import { MdAddBox } from "react-icons/md";
//import MultiCurvesDrawer from "./components/multiCurvesDrawer/MultiCurvesDrawer";

const MyBatiment = () => {
    document.title = "kWh50 - Mon bâtiment";

    //on récupère les données de la page précédente
    const { state } = useLocation();

    //variable permettant de gérer les dates sélectionnées
    const [currentDate, setCurrentDate] = useState({
        startDate: state.startDate ? state.startDate : null,
        endDate: state.endDate ? state.endDate : null,
    });

    //variable permettant de gérer le capteur courant
    const [currentSensor, setCurrentSensor] = useState<sensor>(
        state.currentSensor ? state.currentSensor : null
    );

    //fetch des données des capteurs
    const { data, isLoading } = useSWR(
        {
            url: "/sensor/get-sensors",
            params: { idbat: state.batiment.id_bat },
        },
        GetFetcher,
        {
            revalidateOnFocus: false,
        }
    );

    //gère l'ouverture et la fermeture du drawer du sous comptage
    const {
        isOpen: isScOpen,
        onClose: onScClose,
        onOpen: onScOpen,
    } = useDisclosure();

    //gère l'ouverture et la fermeture du drawer du multi-courbes
    const {
        isOpen: isMCOpen,
        onClose: onMCClose,
        onOpen: onMCOpen,
    } = useDisclosure();

    useEffect(() => {
        if (!data) return;

        //on met à jour le capteur courant avec une valeur par défaut
        setCurrentSensor(data.sensor[0]);
    }, [data]);

    return (
        <div className="myBatiment">
            {state != null && (
                <>
                    <Header
                        title={state.batiment.nom_bat.replace(/<[/]?em>/g, "")}
                    />
                    <NavBarLogged />
                    <main>
                        <div id="first-line">
                            <BackButton currentBatiment={state.batiment} nomSite={state.nomSite} />
                            {isLoading ? (
                                <Loading />
                            ) : (
                                <>
                                    <SelectSensor
                                        data={data.sensor}
                                        setCurrentSensor={setCurrentSensor}
                                        currentSensor={currentSensor}
                                    />
                                    <SelectMeasure
                                        data={data.sensor}
                                        currentSensor={currentSensor}
                                        setCurrentSensor={setCurrentSensor}
                                    />
                                    {/* <button onClick={onMCOpen}>
                                        <MdAddBox
                                            id="add-icon"
                                            size={40}
                                        />
                                    </button> */}
                                </>
                            )}
                        </div>
                        <div id="second-line">
                            {currentSensor &&
                                (currentSensor.capteurs_Sous_Comptage.length >
                                    0 ||
                                    currentSensor.sous_compteur) && (
                                    <Button onClick={onScOpen}>
                                        Sous comptage
                                    </Button>
                                )}
                            {currentSensor && (
                                <DateRange
                                    currentSensor={currentSensor}
                                    currentDate={currentDate}
                                    setCurrentDate={setCurrentDate}
                                />
                            )}
                            {currentSensor && (
                                <SettingsButton
                                    currentSensor={currentSensor}
                                    currentDate={currentDate}
                                    currentBatiment={state.batiment}
                                />
                            )}
                        </div>
                        {currentSensor &&
                            currentDate.startDate &&
                            currentDate.endDate && (
                                <>
                                    <MyBatimentChart
                                        currentSensor={currentSensor}
                                        currentDate={currentDate}
                                        currentBatiment={state.batiment}
                                    />
                                </>
                            )}
                    </main>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <SubCounterDrawer
                                isOpen={isScOpen}
                                onClose={onScClose}
                                sensors={data.sensor}
                                currentSensor={currentSensor}
                                setCurrentSensor={setCurrentSensor}
                            />
                            {/* <MultiCurvesDrawer
                                onClose={onMCClose}
                                isOpen={isMCOpen}
                            /> */}
                        </>
                    )}
                </>
            )}
        </div>
    );
};

export default MyBatiment;
