import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
    Box,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { batiment, site } from "@tsTypes/mySitesTypes";
import "./mySitesAccordion.scss";
import { FaCity } from "react-icons/fa6";
import { FaBuilding } from "react-icons/fa6";

const MySiteAccordion = (props: any) => {
    return (
        <>
            {props.dataSite && (
                <Accordion
                    className="MySiteAccordion"
                    allowToggle
                >
                    {props.dataSite.length === 0 ? (
                        <h2>
                            Aucuns sites ne correspondent à vos critères de
                            recherche
                        </h2>
                    ) : (
                        props.dataSite.map((_site: site) => {
                            if (_site.nb_batiments === 1) {
                                return (
                                    <div
                                        className="lienSimple"
                                        key={_site.batiments[0].id_bat}
                                    >
                                        <Link
                                            to={"/user/dashboard-batiment"}
                                            state={{
                                                batiment: _site.batiments[0],
                                                nomSite: _site.nom_site,
                                            }}
                                        >
                                            <div>
                                                <FaBuilding color="#578830" />
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html: _site
                                                            .batiments[0]
                                                            .nom_bat,
                                                    }}
                                                />
                                            </div>
                                            <Badge
                                                backgroundColor="#578830"
                                                color="#fff"
                                            >
                                                {_site.batiments[0].nb_capteur}{" "}
                                                {_site.batiments[0]
                                                    .nb_capteur === "1"
                                                    ? "capteur"
                                                    : "capteurs"}
                                            </Badge>
                                        </Link>
                                    </div>
                                );
                            }
                            return (
                                <AccordionItem key={_site.id_site}>
                                    <h2>
                                        <AccordionButton>
                                            <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                            >
                                                <div>
                                                    <FaCity color="#117166" />
                                                    <p dangerouslySetInnerHTML={{ __html: _site.nom_site}} />
                                                </div>
                                                <div>
                                                    <Badge
                                                        backgroundColor="#578830"
                                                        color="#fff"
                                                    >
                                                        {_site.batiments.reduce(
                                                            (acc, batiment) => {
                                                                return (
                                                                    acc +
                                                                    parseInt(
                                                                        batiment.nb_capteur,
                                                                        10
                                                                    )
                                                                );
                                                            },
                                                            0
                                                        )}{" "}
                                                        capteurs
                                                    </Badge>
                                                    <AccordionIcon />
                                                </div>
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        <ul>
                                            {_site.batiments.map(
                                                (_batiment: batiment) => {
                                                    return (
                                                        <li
                                                            key={
                                                                _batiment.id_bat
                                                            }
                                                        >
                                                            <Link
                                                                to={
                                                                    "/user/dashboard-batiment"
                                                                }
                                                                state={{
                                                                    batiment:
                                                                        _batiment,
                                                                    nomSite:
                                                                        _site.nom_site.replace(
                                                                            /<[/]?em>/g,
                                                                            ""
                                                                        ),
                                                                }}
                                                            >
                                                                <div>
                                                                    <FaBuilding color="#578830" />
                                                                    <p
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: _batiment.nom_bat,
                                                                        }}
                                                                    />
                                                                </div>
                                                                <Badge
                                                                    backgroundColor="#578830"
                                                                    color="#fff"
                                                                >
                                                                    {
                                                                        _batiment.nb_capteur
                                                                    }{" "}
                                                                    {_batiment.nb_capteur ===
                                                                    "1"
                                                                        ? "capteur"
                                                                        : "capteurs"}
                                                                </Badge>
                                                            </Link>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                    </AccordionPanel>
                                </AccordionItem>
                            );
                        })
                    )}
                </Accordion>
            )}
        </>
    );
};

export default MySiteAccordion;
