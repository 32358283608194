import { sensorType, sensorUnite } from "@tsTypes/sensorType";
import {
    formatDistanceToNow,
} from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";
import "./dataDisplay.scss";

interface props {
    d: {
        type: sensorType;
        unite: sensorUnite;
        valeur: number;
        timestamp: number;
    };
}

export default function DataDisplay({ d }: props) {
    return (
        <div className="data-display">
            <h1>{d.type}</h1>
            <p className="data-display-value">
                {d.valeur} {d.unite}
            </p>
            <p className="data-display-date">
                {formatDistanceToNow(new Date(d.timestamp * 1000), {
                    addSuffix: true,
                    locale: fr,
                })}
            </p>
        </div>
    );
}
