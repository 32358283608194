import { GetFetcher } from "@api/SwrFetcher";
import Loading from "@components/loading/Loading";
import SelectMeasure from "@pages/private/user/myBatiment/components/selectMeasure/SelectMeasure";
import SelectSensor from "@pages/private/user/myBatiment/components/selectSensor/SelectSensor";
import { batiment } from "@tsTypes/mySitesTypes";
import { sensor } from "@tsTypes/sensorType";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import ChartDashboardBatiment from "./components/ChartDashboardBatiment";
import "./chartPanel.scss";

interface props {
    currentBatiment: batiment;
}

export default function ChartPanel({ currentBatiment }: props) {
    //variable permettant de gérer le capteur courant
    const [currentSensor, setCurrentSensor] = useState<sensor>(null);

    //fetch des données des capteurs
    const { data, isLoading } = useSWR(
        {
            url: "/sensor/get-sensors",
            params: { idbat: currentBatiment?.id_bat },
        },
        GetFetcher,
        {
            revalidateOnFocus: false,
        }
    );

    useEffect(() => {
        if (!data) return;

        //on met à jour le capteur courant avec une valeur par défaut
        setCurrentSensor(data.sensor[0]);
    }, [data]);

    if (isLoading || !currentBatiment) return <Loading />;

    return (
        <div className="dashboard-batiment-chart-panel">
            <div className="select">
                <SelectSensor
                    currentSensor={currentSensor}
                    data={data.sensor}
                    setCurrentSensor={setCurrentSensor}
                />
                {currentSensor ? (
                    <SelectMeasure
                        currentSensor={currentSensor}
                        data={data.sensor}
                        setCurrentSensor={setCurrentSensor}
                    />
                ) : (
                    <Loading />
                )}
            </div>
            <div className="chart">
                {currentSensor ? (
                    <ChartDashboardBatiment
                        currentSensor={currentSensor}
                        currentBatiment={currentBatiment}
                    />
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    );
}
