import React from "react";
import { Link } from "react-router-dom";
import "./backButton.scss";
import { batiment } from "@tsTypes/mySitesTypes";

interface props {
    currentBatiment: batiment;
    nomSite: string;
}

export default function BackButton({ currentBatiment, nomSite }: props) {
    return (
        <Link
            id="back-button"
            to={"/user/dashboard-batiment"}
            state={{
                batiment: currentBatiment,
                nomSite: nomSite,
            }}
        >
            Retour
        </Link>
    );
}
