import { sendGAPageView } from "@api/analytics";
import { batiment } from "@tsTypes/mySitesTypes";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Panel3d from "./components/3dPanel/Panel3d";
import NavBarLogged from "@components/navBarLogged/NavbarLogged";
import Header from "@components/header/Header";
import "./dashboardBatiment.scss";
import MapPanel from "./components/mapPanel/MapPanel";
import No3d from "@assets/gif/no_3d.gif";
import ChartPanel from "./components/chartPanel/ChartPanel";

export default function DashboardBatiment() {
    document.title = "kWh50 - Dashboard Batiment";

    //on récupère le batiment courant
    const { state } = useLocation();

    const navigate = useNavigate();

    const [currentBatiment, setCurrentBatiment] = useState<batiment>();

    useEffect(() => {
        sendGAPageView(document.title, window);

        if (state) {
            setCurrentBatiment(state.batiment as batiment);
        } else {
            navigate("/user/mes-sites");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="dashboard-batiment">
            <Header title={(state.batiment as batiment).nom_bat} />
            <NavBarLogged />
            <main>
                <div id="nav">
                    <Link className="btn-prev" to="/user/mes-sites">Retour vers mes sites</Link>
                    <Link
                        to="/user/mon-batiment"
                        className="btn-next"
                        state={{
                            batiment: state.batiment,
                            nomSite: state.nomSite,
                        }}
                    >
                        Mes consos en détail
                    </Link>
                </div>
                <section className="dashboard">
                    {state.batiment.id_smplr && currentBatiment ? (
                        <Panel3d batiment={currentBatiment} />
                    ) : (
                        <div id="no-3d">
                            <img src={No3d} alt="3d illustration" />
                            <h2>Pas de visualisation 3d</h2>
                            <p>
                                (pour obtenir une version 3d de vos batiments
                                veuillez contacter{" "}
                                <a
                                    href="mailto:contact@kwh50.io"
                                    type="mail"
                                >
                                    contact@kwh50.io
                                </a>
                                )
                            </p>
                        </div>
                    )}
                    <MapPanel batiment={currentBatiment} />
                    <ChartPanel currentBatiment={currentBatiment} />
                </section>
            </main>
        </div>
    );
}
