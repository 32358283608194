import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
} from "@chakra-ui/react";
import { AssetRoom } from "@tsTypes/smplrspaceTypes";
import React from "react";
import DataDisplay from "./components/dataDisplay/DataDisplay";
import "./roomDrawer.scss";

interface props {
    d: AssetRoom;
    isOpen: boolean;
    onClose: () => void;
}

export default function RoomDrawer({ d, isOpen, onClose }: props) {
    //si pas de données, on retourne null
    if (!d) return null;

    return (
        <Drawer
            isOpen={isOpen}
            onClose={onClose}
            id="room-drawer"
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>
                    <h1>Dernières mesures</h1>
                </DrawerHeader>
                <DrawerCloseButton color="white" />
                <DrawerBody>
                    {d.data.map((d) => (
                        <DataDisplay
                            d={d}
                            key={d.type}
                        />
                    ))}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
}
