import { AssetRoom } from "@tsTypes/smplrspaceTypes";
import {
    calculateComfortIndex,
    determineRoomColor,
} from "@utils/dashboardBatiment";
import React from "react";

interface props {
    d: AssetRoom;
}

export default function Tooltip(d: AssetRoom) {
    const temperature = d.data.find((d) => d.type === "température")?.valeur;
    const humidity = d.data.find((d) => d.type === "humidité")?.valeur;

    let comfortIndex = calculateComfortIndex(temperature, humidity);

    return `${d.description}<br/>
            Indice de confort: <b style="color: ${determineRoomColor(
                comfortIndex
            )}">${comfortIndex}</b> (parfait: 0)
            `;
}
