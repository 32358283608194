import { CreateToastFnReturn } from "@chakra-ui/react";
import { batiment } from "@tsTypes/mySitesTypes";
import { sensor } from "@tsTypes/sensorType";
import { determineUnit } from "@utils/chartFunctions";
import Highcharts, { PointOptionsObject } from "highcharts";

function findMax(acc: number, item: [number, number]) {
    return item[1] > acc ? item[1] : acc;
}

export function EnergyChart(
    sensor: sensor,
    batiment: batiment,
    mesures: [number, number][],
    index: [number, number][],
    plotbands: Highcharts.AxisPlotBandsOptions[],
    toast: CreateToastFnReturn = null,
    simpleMode: boolean = false
): Highcharts.Options {

    //on récupère les valeurs max des mesures et de l'index pour déterminer l'unité
    let maxIndexValue = index.reduce(findMax, 0);

    let { divider: indexDivider, unit: indexUnit } = determineUnit(
        maxIndexValue,
        sensor
    );

    //on divise les valeurs par le diviseur pour afficher les unités correctes
    mesures = mesures.map(([date, value]) => [date, value * 60]);
    index = index.map(([date, value]) => [date, value / indexDivider]);

    return {
        chart: {
            marginLeft: 75,
            zooming: {
                type: "x",
                mouseWheel: {
                    enabled: true,
                },
            },
            marginRight: 75,
            events: {
                load: !simpleMode ? function () {
                    const chart = this;
                    let diviser = false;

                    // Bouton de réinitialisation du zoom
                    chart.renderer
                        .button("Reset zoom", 0, 0, function () {
                            chart.zoomOut();
                        })
                        .attr({ zIndex: 999 })
                        .align({ align: "right", x: -100, y: 90 })
                        .add();
                } : null,
            },
        },
        accessibility: {
            enabled: false,
        },
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 910,
                    },
                    chartOptions: {
                        title: {
                            text: sensor.type_energie,
                        },
                    },
                },
            ],
        },
        rangeSelector: {
            enabled: false,
        },
        navigator: {
            enabled: simpleMode ? false : true,
        },
        legend: {
            enabled: simpleMode ? false : true,
            itemStyle: {
                fontSize: "16px",
            },
        },
        tooltip: {
            valueDecimals: 2,
            xDateFormat: "%A %d %B %Y %H:%M:%S",
        },
        title: {
            text: `Consommation instantanée et cumulée ${batiment.nom_bat} - ${sensor.type_energie}`,
            y: 20,
            align: "center",
            margin: 40,
            style: {
                fontSize: "20px",
            },
        },
        xAxis: {
            type: "datetime",
            labels: {
                rotation: 0, // Désactive la rotation des étiquettes de l'axe X
                style: {
                    fontSize: "10px", // Réduit la taille de police des étiquettes de l'axe X
                },
            },
            plotBands: plotbands,
        },
        yAxis: [
            {
                title: {
                    text: `Consommations instantanées (W)`,
                    align: "high", // Aligne le titre de l'axe gauche en haut
                    textAlign: "left",
                    y: -15, // Déplace le titre plus haut que le début du graphique
                    rotation: 0, // Désactive la rotation du titre de l'axe gauche
                    style: {
                        color: "#628fc9", // Utilise la première couleur de la palette Highcharts,
                        fontSize: "13px",
                        fontWeight: "bold",
                        width: 250,
                    },
                },
                labels: {
                    format: `{value}`,
                },
                opposite: false,
                //endOnTick: true, // Permet à l'axe y de s'ajuster en fonction des données
            },
            {
                title: {
                    text: `Somme des consommations (${indexUnit}Wh)`,
                    align: "high", // Aligne le titre de l'axe gauche en haut
                    textAlign: "right",
                    y: -15, // Déplace le titre plus haut que le début du graphique
                    rotation: 0, // Désactive la rotation du titre de l'axe gauche
                    style: {
                        color: "purple", // Utilise la première couleur de la palette Highcharts,
                        fontSize: "13px",
                        fontWeight: "bold",
                        width: 250,
                    },
                },
                opposite: true,
            },
        ],
        boost: {
            enabled: true,
        },
        series: [
            {
                type: "area",
                name: "mesures instantanées",
                data: mesures as (
                    | number
                    | [string | Date, number]
                    | PointOptionsObject
                )[],
                fillColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1.4 },
                    stops: [
                        [0, Highcharts.getOptions().colors[0].toString()],
                        [
                            1,
                            Highcharts.color(Highcharts.getOptions().colors[0])
                                .setOpacity(0)
                                .get("rgba")
                                .toString(),
                        ],
                    ],
                },
                tooltip: {
                    valueSuffix: `  W`,
                },
            },
            {
                type: "line",
                name: "somme des consommations",
                data: index as (
                    | number
                    | [string | Date, number]
                    | PointOptionsObject
                )[],
                yAxis: 1,
                tooltip: {
                    valueSuffix: ` ${indexUnit}Wh`,
                },
            },
        ],
    };
}
