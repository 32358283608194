import fetchApi from "@api/fetchApi";
import { sensor } from "@tsTypes/sensorType";
import { formatChartDatas } from "@utils/chartFunctions";
import { PointOptionsObject } from "highcharts";
import { HighchartsReactRefObject } from "highcharts-react-official";
import { useEffect, useMemo, useRef, useState } from "react";

export function useDataFetch(
    url: string,
    params?: any,
    refreshSettings?: {
        refreshTimer: number;
        refreshCallback?: (data: any) => void;
        chartRef: React.MutableRefObject<HighchartsReactRefObject>
        currentSensor: sensor
    },
) {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const timeoutId = useRef<NodeJS.Timeout>(null);

    // eslint-disable-next-line
    const memoizedParams = useMemo(() => params, [JSON.stringify(params)]);

    useEffect(() => {
        //on affiche le loader
        setIsLoading(true);

        //on récupère les données
        fetchApi
            .get(url, params)
            .then((res) => {
                //on stocke les données
                setData(res.data);
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                //si on a des settings de refresh on lance l'interval
                if (refreshSettings) {

                    //on lance l'interval
                    const intervalId = setTimeout(() => {
                        console.log("refreshing data");
                        //on récupère les données
                        fetchApi.get(url, params).then((res) => {
                            console.log("res ", res);
                            //si la ref du graphique est définie on met à jour le graphique
                            if(refreshSettings.chartRef.current){
                                //on formate les données pour les afficher dans le graphique
                                const mesure = formatChartDatas(
                                    res.data.mesures,
                                    refreshSettings.currentSensor
                                );

                                //on met à jour le graphique pour qu'il n'y ai pas de rerender du composant chart
                                refreshSettings.chartRef.current?.chart.update({
                                    series: [
                                        {
                                            type: refreshSettings.chartRef
                                                .current?.chart.series[0]
                                                .type as unknown as Highcharts.SeriesOptionsType,
                                            data: mesure.mesure as (
                                                | number
                                                | [string | Date, number]
                                                | PointOptionsObject
                                            )[],
                                        },
                                        {
                                            type: "line",
                                            data: mesure.index as (
                                                | number
                                                | [string | Date, number]
                                                | PointOptionsObject
                                            )[],
                                        },
                                    ],
                                });
                            } else {

                                //sinon on met à jour les données
                                setData(res.data);
                            }

                            //on appelle le callback de refresh s'il y en a une
                            if(refreshSettings.refreshCallback){
                                refreshSettings.refreshCallback(res.data);
                            }
                        });
                    }, refreshSettings.refreshTimer);

                    //on stocke l'id de l'interval
                    timeoutId.current = intervalId;
                }

                //on cache le loader
                setIsLoading(false);
            });

        //on kill l'interval si on quitte le composant
        return () => {
            clearTimeout(timeoutId.current);
        };
        // eslint-disable-next-line
    }, [memoizedParams]);

    return { data, isLoading, error };
}
